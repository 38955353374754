<script>
export default {
  name: 'ReportCard',

  components: {
    Icon: () => import('@/components/general/Icon')
  },

  props: {
    title: {
      type: String,
      default: null
    },

    image: {
      type: String,
      default: null
    },

    description: {
      type: String,
      default: null
    }
  }
}
</script>

<template>
  <div
    class="report-card"
    @click="$emit('click')"
  >
    <img
      :src="`/assets/images/themes/default/svg/reports/${image}`"
      alt=""
      class="report-card-image"
    >

    <div class="report-card-info">
      <h2 class="report-card-title">
        {{ title }}
      </h2>

      <p class="report-card-description">
        {{ description }}
      </p>
    </div>

    <div class="report-card-button">
      <Icon
        name="keyboard_arrow_right"
        wrapper
        size="large"
      />
    </div>
  </div>
</template>

<style scoped>
.report-card {
  background: linear-gradient(270deg, transparent 89%, #3b6c9c 89%, #3b6cbc 100%);
  transition: all 150ms ease;
  border: 1px solid #005EB8;
  box-sizing: border-box;
  border-radius: 16px;
  align-items: center;
  padding: 20px 32px;
  cursor: pointer;
  display: flex;
}

.report-card-image {
  margin-right: 40px;
  height: 56px;
  width: 56px;
}

.report-card-info {
  flex-direction: column;
  display: flex;
}

.report-card-title {
  letter-spacing: 1px;
  margin-bottom: 4px;
  color: #3b6c9c;
  font-weight: 900;
  font-size: 20px;
}

.report-card-description {
  letter-spacing: 0.65px;
  color: #3b6c9ccf;
  font-size: 13px;
}

.report-card-button {
  transition: transform 150ms ease;
  margin-left: auto;
  color: #3b6c9c;
}

.report-card:hover {
  filter: brightness(1.05);
}

.report-card:hover .report-card-button {
  transform: translateX(8px);
}
</style>
